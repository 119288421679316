import React, { Key, useRef, useState } from 'react';
import { Check } from 'baseui/icon';
import { FILL, Tab, Tabs } from 'baseui/tabs-motion';
import { useCoreAPIURL, useViewPortSize } from '../../../hooks';
import AboutYourSafe, { IAboutYourSafe } from './AboutYourSafe';
import { ErrorForm } from '../../../atoms/Cards';
import PersonalInformation, { IPersonalInformation } from './PersonalInformation';
import Preferences, { IPreferences } from './Preferences';
import SuccessStep from './SuccessStep';
import analyticsCustomEvent from '../../../atoms/js/analyticsCustomEvent';
import { stackStyled, useStackStyletron } from '../../../theme';


const MainContainer = stackStyled('div', ({ isMobile }: { isMobile: boolean }) => ({
  width: '90%',
  marginTop: isMobile ? '30px' : '70px',
  justifyContent: 'center',
  paddingBottom: '250px',
}));

const TabContainter = stackStyled('div', {
  display: 'flex',
  whiteSpace: 'no-wrap'
})

export interface IFullInformation {
  information: IPersonalInformation;
  about: IAboutYourSafe;
  preferences: IPreferences;
  [key: string]: any;
}

interface Props {}

const RegisterSafeFormBySteps = () => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();
  const personalInformationRef = useRef(null) as any;
  const aboutRef = useRef(null) as any;
  const coreAPI_URL = useCoreAPIURL();

  // STATE
  const [active, setActive] = useState<Key>('information');
  const [showFetchError, setShowFetchError] = useState(false);

  // const [currentStep, setCurrentStep] = useState(1);

  const [steps, setSteps] = useState([
    { title: 'Personal Information', key: 'information', step: 1, done: false },
    { title: 'About your safe', key: 'about', step: 2, done: false },
    { title: 'Preferences', key: 'preferences', step: 3, done: false },
  ]);

  const [fullInformation, setFullInformation] = useState({} as IFullInformation);
  // EFFECTS

  // HANLDERS && FUNCTIONS


  async function sendInfo(value: any) {
    const response = await fetch(coreAPI_URL + '/register', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  async function handleSubmitClicked(value: any) {
    const { about = {} as IAboutYourSafe } = value;
    const { proofOfPurchase = {} as File, serialNumber } = about;
    const myRenamedFile = new File(
      [proofOfPurchase],
      new Date().getTime().toString() +
        '-' +
        serialNumber.toString() +
        '-upload-register-safe-file' +
        '.' +
        proofOfPurchase.name.split('.')[proofOfPurchase.name.split('.').length - 1]
    );
    const formData = new FormData();
    formData.append('file', myRenamedFile);
    const response = await fetch(coreAPI_URL + '/upload', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        // Accept: 'multipart/form-data',
      },
      body: formData,
    })
      .then((r) => r.json())
      .then((r) => {
        const { data = {} as { url: string }, message } = r;
        if (message === 'File saved.') {
          const { url = '' } = data;
          value.about.proofOfPurchase = url;
          sendInfo(value);
        } else {
          setShowFetchError(true);
        }
        return r;
      })
      .catch((e) => setShowFetchError(true));
  }

  function handleChange(value: any, key: string) {
    const fullInformationTmp = { ...fullInformation };
    fullInformationTmp[key] = value;
    setFullInformation(fullInformationTmp);
    if (key === 'preferences') {
      handleSubmitClicked(fullInformationTmp);
    }
  }

  function handleNextStepClicked(value: any, key: string) {
    handleChange(value, key);
    const stepsTmp = steps.map((s) => {
      if (s.key === key) {
        s.done = true;
      }
      return s;
    });
    setSteps(stepsTmp);

    switch (key) {
      case 'information':
        setActive('about');
        break;
      case 'about':
        setActive('preferences');
        break;
      case 'preferences':
        setActive('last');
        break;
    }
  }

  function handleTabsChanged(value: string) {
    if (value === 'about' && active === 'information') {
      personalInformationRef.current.triggerSubmit();
    }
    if (value === 'preferences' && active === 'about') {
      aboutRef.current.triggerSubmit();
    }
    if ((active === 'about' || active === 'preferences') && value === 'information') {
      setActive(value);
    }
    if (active === 'preferences' && value === 'about') {
      setActive(value);
    }
    if (active === 'information' && value === 'preferences') {
      personalInformationRef.current.triggerSubmit();
    }
  }


  // RENDER

  const { information = {} as IPersonalInformation, about = {} as IAboutYourSafe } =
    fullInformation;

  return (
    <MainContainer isMobile={isMobile} id='register-your-safe-main-container'>
      {showFetchError && <ErrorForm />}
      <Tabs
        activeKey={active}
        onChange={({ activeKey }) => handleTabsChanged(activeKey as string)}
        activateOnFocus
        fill={FILL.fixed}
        overrides={{
          Root: {
            style: () => ({
              minHeight: active !== 'last' ? '60vh' : '15vh',
              // overflowX: 'scroll'
            }),
          },
          TabHighlight: {
            style: () => ({
              color: theme.stackOnColors.primaryYellow,
              backgroundColor: 'transparent',
              height: '2px',
              '&:hover': {
                background: 'transparent',
              },
            }),
          },
          TabBorder: {
            style: () => ({
              backgroundColor: 'transparent',
              borderRadius: '2.5px',
              marginTop: '3px',
            }),
          },
        }}
      >
        {steps.map((item) => (
          <Tab
            disabled={active === 'last'}
            key={item.key}
            title={item.title}
            artwork={() => (
              <div
                style={
                  item.key === active || item.done
                    ? {
                        borderRadius: '50%',
                        width: isMobile ? '20px' : '30px',
                        color: 'white',
                        height: isMobile ? '20px' : '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // fontSize: isMobile ? '12px' : '14px !important',
                        background:
                          item.done 
                            ? theme.stackOnColors.primaryGreen
                            : theme.stackOnColors.primaryYellow,
                      }
                    : {
                        borderRadius: '50%',
                        width: isMobile ? '20px' : '30px',
                        height: isMobile ? '20px' : '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: theme.stackOnColors.primaryBlack,
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: theme.stackOnColors.backgroundBlack,
                        fontSize: '14px !important'
                      }
                }
              >
                {item.done ? <Check size={isMobile ? '18px' : '25px'} /> : item.step}
              </div>
            )}
            overrides={{
              Tab: {
                style: () => ({
                  pointerEvents: 'none',
                  width: isMobile ? '20%' : '100%',
                  paddingLeft: isMobile ? `0px !important` : 'inherit',
                  paddingRight: isMobile ? `0px !important` : 'inherit',
                  paddingBottom: '12px',
                  background: 'transparent',
                  fontFamily: 'Montserrat !important',
                  justifyContent: 'flex-start',
                  fontSize: isMobile ? '10px' : '16px',
                  textAlign: 'left',
                  fontWeight: 700,
                  borderBottom: '2px solid',
                  borderColor: item.done
                    ? theme.stackOnColors.primaryGreen
                    : active === item.key
                    ? theme.stackOnColors.primaryYellow
                    : theme.stackOnColors.backgroundBlack,
                  color: item.done 
                    ? theme.stackOnColors.primaryGreen
                    : active === item.key
                    ? theme.stackOnColors.primaryYellow
                    : active === 'last'
                    ? theme.stackOnColors.primaryGreen
                    : theme.stackOnColors.primaryBlack,
                  '&:active': {
                    fontSize: '16px !important',
                  },
                  '&:hover': {
                    background: 'transparent',
                  },
                }),
              },
            }}
          >
            {item.key === 'information' && (
              <PersonalInformation
                ref={personalInformationRef}
                informationProp={information}
                stepPassed={(value: IPersonalInformation) =>
                  handleNextStepClicked(value, 'information')
                }
              />
            )}
            {item.key === 'about' && (
              <AboutYourSafe
                ref={aboutRef}
                stepPassed={(value: IAboutYourSafe) => handleNextStepClicked(value, 'about')}
                aboutYourSafeProp={about}
              />
            )}
            {item.key === 'preferences' && (
              <Preferences
                stepPassed={(value) => {
                  analyticsCustomEvent('register_safe');
                  handleNextStepClicked(value, 'preferences');
                }}
              />
            )}
          </Tab>
        ))}
      </Tabs>
      {active === 'last' && <SuccessStep />}
    </MainContainer>
  );
};

export default RegisterSafeFormBySteps;
