import React, { useRef } from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import RegisterSafeFormBySteps from '../components/sections/RegisterSafeFormBySteps';
import { stackStyled } from '../theme';
import { StackOnBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Containers';
import { useViewPortSize } from '../hooks';

interface Props {}

const BreadCrumbsContainer = stackStyled('div', (props: { isMobile: boolean }) => ({
  position: 'absolute',
  top: props.isMobile ? '10px' : '30px',
  left: 0,
  width: props.isMobile ? '100%' : '580px',
}));

const MainWrapper = stackStyled(Wrapper, (props: { isMobile: boolean; isTablet?: boolean }) => ({
  display: 'flex',
  maxWidth: '1280px',
  width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
  justifyContent: 'center',
  padding: props.isMobile ? '100px 10px' : '50px 10px',
  marginLeft: 'auto',
  marginRight: 'auto',
  overflowX: 'hidden',
  textAlign: 'center',
}));

export const RegisterProduct: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const [isMobile, isTablet] = useViewPortSize();

  // STATE

  // HANLDERS && FUNCTIONS
  let addTitle = props.location.search
    ? props.location.search.split('=')[1].split('+').join(' ')
    : '';

  // RENDER

  return (
    <LayoutProvider seo locationSearch={props.location.search} titleExt={addTitle}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll>
        <BreadCrumbsContainer isMobile={isMobile}>
          <StackOnBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Customer service', href: '/customer-service' },
              { title: 'Register your product', href: '/product-registration' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          <RegisterSafeFormBySteps />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default RegisterProduct;
