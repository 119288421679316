import React, { useEffect, useState } from "react";
import SuccessIcon from "../../../assets/product-safe.svg";
import { Button } from "../../../atoms";
import scrollTo from "gatsby-plugin-smoothscroll";
import RegisterSurvey from "../RegisterSurvey";
import { stackStyled, Typography, useStackStyletron } from "../../../theme";
import { useViewPortSize } from "../../../hooks";
import AnchorSuccess from "../../../atoms/Button/AnchorSuccess";
import '../RegisterSurvey/modal.css';

interface IModalInfo {
  insuranceInterested: string;
  averageValue: string;
  commonItem: string;
}

const MainContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    // height: '60vh',
    paddingTop: isMobile ? 0 : "30px",
  })
);

const ButtonContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    margin: "10px",
    width: isMobile ? "80%" : "auto",
  })
);

const ButtonsContainer = stackStyled(
  "div",
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: "30px",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    alignItems: "center",
    justifyContent: "center",
  })
);

const Modal = stackStyled("div", ({ show }: { show: boolean }) => ({
  display: show ? "block" : "none",
  position: "fixed",
  zIndex: 1,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  maxHeight: '800px',
  overflow: "auto",
  backgroundColor: `rgba(0, 0, 0, 0.4)`,
  paddingTop: '10%',
}));

const ModalContent = stackStyled("div", {
  position: "relative",
  backgroundColor: "#fefefe",
  margin: "auto",
  padding: 0,
  borderRadius: "16px",
  border: "1px solid #888",
  width: "80%",
  maxWidth: "960px",
  boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
  animationName: "animatetop",
  textAlign: 'left',
  animationDuration: "0.4s",
});

const ModalBody = stackStyled("div", {
  padding: "70px",
});

const SuccessStep = () => {
  // HOOKS

  const [isMobile, isTablet] = useViewPortSize();
  const [css, theme] = useStackStyletron();
  const [modalOn, setModalOn] = useState(false);
  const [modalInfo, setModalInfo] = useState({} as IModalInfo);
  // EFFECTS

  function showModal() {
    setTimeout(() => {
      setModalOn(true);
    }, 3000);
  }

  useEffect(() => {
    setTimeout(() => {
      scrollTo("#register-your-safe-main-container");
      showModal();
    }, 500);
  }, []);

  const {
    insuranceInterested = "",
    averageValue = "",
    commonItem = "",
  } = modalInfo;

  let modalSelected = document.getElementById("surveyModal");
  window.onclick = function (event) {
    if (event.target == modalSelected) {
      modalSelected.style.display = "none";
    }
  };

  return (
    <MainContainer isMobile={isMobile}>
      {!isMobile && (
        <Modal show={modalOn} id={"surveyModal"}>
          <ModalContent>
            <ModalBody>
              <RegisterSurvey />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {isMobile && <RegisterSurvey />}
      {!isMobile && (
        <>
          <img
            src={SuccessIcon}
            alt={"Success Icon"}
            className={css({ alignSelf: "center", width: '140px', height: '140px' })}
          />
          <Typography
            $fontFamily={"Montserrat"}
            $weight={700}
            $tagType={"h4"}
            $color={theme.stackOnColors.primaryGreenDark}
            $textAlign={"center"}
            $paddingBottom={"12px"}
            $margin='0'
          >
            Product registered successfully!
          </Typography>
          <Typography
            $fontFamily={"Montserrat"}
            $weight={400}
            $tagType={"body"}
            $color={theme.stackOnColors.primaryBlack}
            $textAlign={"center"}
            $margin='0'
          >
            Thanks for contacting us! We will be in touch with you shortly.
          </Typography>
          <ButtonsContainer isMobile={isMobile}>
            <ButtonContainer isMobile={isMobile}>
              <AnchorSuccess
                isOutlined={true}
                size="large"
                fullWidth={isMobile}
                href='/'
              >
                Back to home
              </AnchorSuccess>
            </ButtonContainer>
            <ButtonContainer isMobile={isMobile}>
              <Button.RoundedButton
                size="large"
                color="primary"
                fullWidth={isMobile}
                onClick={() => {
                  window.location.href = "/customer-service/product-manuals";
                }}
              >
                Download user's manual
              </Button.RoundedButton>
            </ButtonContainer>
          </ButtonsContainer>
        </>
      )}
    </MainContainer>
  );
};

export default SuccessStep;
